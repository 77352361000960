<template>
  <div>
    <b-row>
      <b-col xl="3" lg="4" md="5">
        <FormCardProfileVue :loading="loading" />
      </b-col>
      <b-col xl="9" lg="8" md="7">
        <b-tabs>
          <b-tab v-if="verificarPermissao('ROLE_ADMIM_CONSULTAR')">
            <template #title>
              <feather-icon icon="UserIcon" />
              <span>Pessoal</span>
            </template>
            <FormEmployee :loading="loading" />
          </b-tab>

          <b-tab v-if="verificarPermissao('ROLE_FINAN_CONSULTAR')">
            <template #title>
              <feather-icon icon="DollarSignIcon" />
              <span>Financeiro</span>
            </template>
            <FormFinacialVue />
          </b-tab>

          <b-tab v-if="verificarPermissao('ROLE_ATEND_CONSULTAR')">
            <template #title>
              <feather-icon icon="PhoneIcon" />
              <span>Atendimento</span>
            </template>
            <FormAttendanceVue />
          </b-tab>

          <b-tab v-if="verificarPermissao('ROLE_ADMIM_CONSULTAR')">
            <template #title>
              <feather-icon icon="LinkIcon" />
              <span>Vinculos</span>
            </template>
            <FormLinkVue />
          </b-tab>

          <b-tab v-if="verificarPermissao('ROLE_ADMIM_CONSULTAR')">
            <template #title>
              <feather-icon icon="FileTextIcon" />
              <span>Documentos</span>
            </template>
            <FormDocumentationsVue />
          </b-tab>

          <b-tab disabled>
            <template #title>
              <feather-icon icon="CalendarIcon" />
              <span>Eventos</span>
            </template>
            <FormEventsVue />
          </b-tab>

          <b-tab v-if="verificarPermissao('ROLE_CORE_CONSULTAR')">
            <template #title>
              <feather-icon icon="FileIcon" />
              <span>Protocolos</span>
            </template>
            <FormProtocolVue />
          </b-tab>

          <b-tab v-if="verificarPermissao('ROLE_RELATORIO_CONSULTAR')">
            <template #title>
              <feather-icon icon="PrinterIcon" />
              <span>Relatorios</span>
            </template>
            <FormReportsVue />
          </b-tab>
        </b-tabs>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import { BRow, BCol, BTabs, BTab } from 'bootstrap-vue'

import mixinsGlobal from '@/mixins'
import FormEmployee from '../employees/forms/personal/FormEmployee.vue'
import FormFinacialVue from '../employees/forms/finance/FormFinancial.vue'
import FormAttendanceVue from '../employees/forms/attendance/FormAttendance.vue'
import FormDocumentationsVue from '../employees/forms/documentations/FormDocumentations.vue'
import FormEventsVue from '../employees/forms/events/FormEvents.vue'
import FormReportsVue from '../employees/forms/report/GridReport.vue'
import FormLinkVue from '../employees/forms/link/FormLink.vue'
import FormProtocolVue from '../employees/forms/protocol/FormProtocol.vue'
import FormCardProfileVue from '../employees/forms/personal/FormCardProfileEmployee.vue'

export default {
  components: {
    BRow,
    BCol,
    BTabs,
    BTab,
    FormEmployee,
    FormFinacialVue,
    FormAttendanceVue,
    FormDocumentationsVue,
    FormEventsVue,
    FormReportsVue,
    FormCardProfileVue,
    FormLinkVue,
    FormProtocolVue
  },
  mixins: [mixinsGlobal],
  data() {
    return {
      loading: true
    }
  },
  async mounted() {
    this.loading = true
    try {
      const response = await this.$store.dispatch(
        'employeesAdministrativeModule/getEmployeeID',
        this.$route.params.id
      )
      const objectAssociate = response.data
      if (objectAssociate.foto) {
        objectAssociate.foto = `data:image/jpeg;base64,${objectAssociate.foto}`
      }
      await this.$store.dispatch(
        'employeesAdministrativeModule/prepareToEditFullEmployee',
        objectAssociate
      )
      await this.$store.dispatch(
        'employeesAdministrativeModule/loadComboGenero'
      )
      await this.$store.dispatch(
        'employeesAdministrativeModule/loadComboEstadoCivil'
      )
      await this.$store.dispatch(
        'employeesAdministrativeModule/loadComboProfissoes'
      )
      await this.$store.dispatch(
        'employeesAdministrativeModule/loadComboNaturalidades'
      )
      await this.$store.dispatch(
        'employeesAdministrativeModule/loadComboNacionalidades'
      )
      await this.$store.dispatch(
        'employeesAdministrativeModule/loadComboTypesRegister'
      )
      await this.$store.dispatch('employeesAdministrativeModule/loadComboUfs')
      await this.$store.dispatch(
        'employeesAdministrativeModule/loadComboPrevidenciaSocial'
      )
      await this.$store.dispatch(
        'employeesAdministrativeModule/loadComboTipoEndereco'
      )
      await this.$store.dispatch(
        'employeesAdministrativeModule/loadComboSituacaoRegistro'
      )
      await this.$store.dispatch(
        'employeesAdministrativeModule/loadComboSetores'
      )
      await this.$store.dispatch('combosModule/loadComboFaixaContribuicao')
      this.loading = false
    } catch {
      this.MensagemError('Error', 'Erro ao carregar dados da tela!')
      this.loading = false
      this.$router.go(-1)
    }
  }
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
